import React from 'react'

import { Link } from 'react-router-dom'

// import PhoneGreen from '../../assets/images/phone-incoming--03.png'
// import PhonePurple from '../../assets/images/phone-purple--03.png'
import YoutubeWhite from '../../assets/images/youtube--white.svg'
import TiktokWhite from '../../assets/images/tiktok--white.svg'
import TwitterWhite from '../../assets/images/twitter--white.svg'
import FacebookWhite from '../../assets/images/facebook--white.svg'
import InstagramWhite from '../../assets/images/instagram--white.svg'
import LinkedinWhite from '../../assets/images/linkedin--white.svg'
import WhatsappWhite from '../../assets/images/whatsapp--white.svg'
import EmailWhite from '../../assets/images/mail--white.svg'
import RobotOpenMind from '../../assets/images/robot-open-mind.png'

import './footer.scss'

const Footer = ({footerClassName = ''}) => {
  // const { pathname } = useLocation()
  // let footerClassName = pathname != "/" ? '' : 'dark-footer'

  return (
    <footer className={`background-color--light-purple footer-main-page`}>
      <div className="width-content footer_content">
        <div className="footer_contact">
          <h4 className="text--gray-blue title--xxl footer_main-text">Contacto</h4>
          <div className="footer-main-page__social-media">
            <div className="footer_social-media-container">
              <a
                className="footer_social-media footer_social-media-youtube"
                href=""
                target="_blank"
              >
                <img src={YoutubeWhite} alt="youtube" />
              </a>
              <a
              className="footer_social-media"
              href=""
              target="_blank">
                <img src={TiktokWhite} alt="tiktok" />
              </a>
              <a
                className="footer_social-media footer_social-media-twitter"
                href=""
                target="_blank"
              >
                <img src={TwitterWhite} alt="twitter" />
              </a>
              <a
                className="footer_social-media footer_social-media-facebook"
                href="https://www.facebook.com/profile.php?id=100091892243497"
                target="_blank"
              >
                <img src={FacebookWhite} alt="facebook" />
              </a>
              <a
                className="footer_social-media footer_social-media-instagram"
                href=""
                target="_blank"
              >
                <img src={InstagramWhite} alt="instagram" />
              </a>
              <a
                className="footer_social-media footer_social-media-linkedin"
                href=""
                target="_blank"
              >
                <img src={LinkedinWhite} alt="linkedin" />
              </a>
              <a className="footer_social-media" href="https://wa.me/+5218126001001" target="_blank">
                <img src={WhatsappWhite} alt="whatsapp" />
              </a>
            </div>
            <div>
              <Link to="/" className="button--third--small" onClick={e => {
                e.preventDefault()
              }}>
                <p className="paragraph--highlight">Actívate</p>
              </Link>
            </div>
          </div>
          <div className="footer_contact_content">
            <div>
              <div className="footer_email">
                <a
                className="text--gray-blue paragraph--highlight footer_email_email-address footer-links"
                href="mailto:hola.monterrey@epicgrouplab.com?subject=Mayor%20información">
                  hola.monterrey@epicgrouplab.com
                </a>
              </div>
              <div className="footer_email footer-main-page__second-email">
                <a
                className="text--gray-blue paragraph--highlight footer_email_email-address footer-links"
                href="mailto:expansion@epicgrouplab.com?subject=Mayor%20información">
                  expansion@epicgrouplab.com
                </a>
              </div>
            </div>
            <div className="footer-main-page__know-us-options">
              <a
              href="https://epicgrouplab.com/courses"
              className="text--gray-blue paragraph--highlight footer-links">
                Conoce nuestros cursos
              </a>
              <a
              href="https://epicgrouplab.com/centers"
              className="footer-main-page__know-us-options__second-option text--gray-blue paragraph--highlight footer-links">
                Conoce nuestros centros
              </a>
            </div>
          </div>
          <div className="footer_second-part">
            <ul className="footer_second-part_information">
              <li>
                <a
                className="text--gray-blue paragraph--highlight footer_second-part_information_text footer-links"
                href="https://epicgrouplab.com/legal-warning">
                  Aviso Legal
                </a>
              </li>
              <li>
                <a
                className="text--gray-blue paragraph--highlight footer_second-part_information_text footer-links"
                href="https://epicgrouplab.com/privacy-policy">
                  Política de Privacidad
                </a>
              </li>
              <li>
                <a
                className="text--gray-blue paragraph--highlight footer_second-part_information_text footer-links"
                href="https://epicgrouplab.com/cookies-policy">
                  Política de Cookies
                </a>
              </li>
              <li>
                <a
                className=" text--gray-blue paragraph--highlight footer_second-part_information_text footer-links"
                href="https://epicgrouplab.com/terms-of-sale">
                  Condiciones de venta
                </a>
              </li>
              <li>
                <a
                className=" text--gray-blue paragraph--highlight footer_second-part_information_text footer-links"
                href="https://epicgrouplab.com/warning-about-the-use-of-vr-technology">
                  Advertencia de Uso de Tecnología VR
                </a>
              </li>
            </ul>
          </div>
        </div>
        <figure className="footer-main-page__robot-open-brain-image">
          <img src={RobotOpenMind} alt="robot-open-brain" />
        </figure>
      </div>
    </footer>
  )
}

export default Footer